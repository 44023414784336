import RouterView from '@/components/RouterView'

const menuList = [

  {
    path: '/cockpit/index',
    component: RouterView,
    redirect: '/cockpit/index',
    meta: {
      title: '驾驶舱',
      isMenu: true
    },
    children: [{
      name: 'cockpit',
      path: '/cockpit/index',
      component: () => import('../views/cockpit/index'),
      meta: {
        title: '驾驶舱',
        isMenu: true
      }
    }]
  },

  {
    path: '/selection',
    component: RouterView,
    redirect: '/selection',
    name: 'Selection',
    meta: {
      title: '智选客户中心',
      isMenu: true
    },
    children: [{
        name: 'selection',
        path: '/selection/index',
        component: () => import('../views/selection/index'),
        meta: {
          title: '智选客户中心',
          isMenu: true
        }
      },
      {
        name: 'seleAddenterprise',
        path: '/selection/addenterprise',
        component: () => import('../views/selection/components/addenterprise'),
        meta: {
          title: '编辑基本资料',
          isMenu: true
        }
      },
      {
        name: 'CorrelateUser',
        path: '/selection/CorrelateUser',
        component: () => import('../views/selection/components/accountManagement'),
        meta: {
          title: '账号管理',
          isMenu: true
        }
      },

    ]

  },
  {
    path: '/channel',
    component: RouterView,
    name: 'Channel',
    redirect: '/channel',
    meta: {
      title: '合作伙伴中心',
      isMenu: true
    },
    children: [
      {
        name: 'development',
        path: '/channel/development',
        component: () => import('../views/channel/development/index'),
        meta: {
          title: '合作开发',
          isMenu: true
        }
      },
      {
        name: 'channel',
        path: '/channel/index',
        component: () => import('../views/channel/index'),
        meta: {
          title: '合作伙伴',
          isMenu: true
        }
      },
      {
        name: 'addpartner',
        path: '/channel/addpartner',
        component: () => import('../views/channel/components/addpartner'),
        meta: {
          title: '编辑',
          isMenu: true
        }
      },
      {
        name: 'account',
        path: '/channel/account',
        component: () => import('../views/channel/components/accountmanagement'),
        meta: {
          title: '账号管理',
          isMenu: true
        }
      },
      {
        name: 'rolechannel',
        path: '/channel/rolechannel',
        component: () => import('../views/channel/components/rolechannel'),
        meta: {
          title: '分配权限',
          isMenu: true
        }
      },

      {
        name: 'relevance',
        path: '/channel/relevance',
        component: () => import('../views/channel/components/relevance'),
        meta: {
          title: '关联企业',
          isMenu: true
        }
      },
      {
        name: 'AssociatedOrder',
        path: '/channel/associatedorder',
        component: () => import('../views/channel/components/AssociatedOrder'),
        meta: {
          title: '关联订单',
          isMenu: true
        }
      },
      {
        name: 'ginseng',
        path: '/channel/ginseng',
        component: () => import('../views/channel/ginseng'),
        meta: {
          title: '智参中心',
          isMenu: true
        }
      },
      {
        name: 'addginseng',
        path: '/channel/addginseng',
        component: () => import('../views/channel/components/addginseng'),
        meta: {
          title: '编辑',
          isMenu: true
        }
      },
      {
        name: 'ginsengcompany',
        path: '/channel/ginsengcompany',
        component: () => import('../views/channel/components/ginsengcompany'),
        meta: {
          title: '关联企业',
          isMenu: true
        }
      },
      // {
      //   name: 'Servicesuggestions',
      //   path: '/channel/Servicesuggestions',
      //   component: () => import('../views/channel/Servicesuggestions'),
      //   meta: {
      //     title: '服务建议',
      //     isMenu: true
      //   }
      // },
      // {
      //   name: 'addsuggestions',
      //   path: '/channel/suggestions',
      //   component: () => import('../views/channel/components/addsuggestions'),
      //   meta: {
      //     title: '新增',
      //     isMenu: true
      //   }
      // },
    ]
  },
  {
    path: '/operatorcenter',
    component: RouterView,
    name: 'OperatorManage',
    redirect: '/operatorcenter',
    meta: {
      title: '运营商中心',
      isMenu: true
    },
    children: [{
        path: '/operatorcenter/operatormanage',
        component: () => import('../views/operatorCenter/operatorManage/index'),
        meta: {
          title: '运营商管理',
          isMenu: true
        }
      },
      {
        path: '/operatorcenter/account',
        component: () => import('../views/operatorCenter/account/index'),
        meta: {
          title: '运营商管理',
          isMenu: true
        }
      },
      {
        name: 'NicheExcavate',
        path: '/operatorcenter/nicheexcavate',
        component: () => import('../views/operatorCenter/nicheExcavate/index'),
        meta: {
          title: '商机挖掘',
          isMenu: true
        }
      },
      {
        name: 'NicheDetails',
        path: '/operatorcenter/nicheexcavate/details',
        component: () => import('../views/operatorCenter/nicheExcavate/details'),
        meta: {
          title: '商机详情',
          isMenu: false
        }
      },
      {
        name: 'NicheMatching',
        path: '/operatorcenter/nicheexcavate/matching',
        component: () => import('../views/operatorCenter/nicheExcavate/matchingPage'),
        meta: {
          title: '配置企业',
          isMenu: false
        }
      },
      {
        name: 'Servicesuggestions',
        path: '/channel/Servicesuggestions',
        component: () => import('../views/channel/Servicesuggestions'),
        meta: {
          title: '服务建议',
          isMenu: true
        }
      },
      {
        name: 'addsuggestions',
        path: '/channel/suggestions',
        component: () => import('../views/channel/components/addsuggestions'),
        meta: {
          title: '新增',
          isMenu: true
        }
      },
    ]
  },
  {
    path: '/operate',
    name: 'Activity',
    component: RouterView,
    redirect: '/operate/archive',
    meta: {
      // title: '运营档案中心',
      title: '运营管理中心',
      isMenu: true,
      keepAlive: true
    },
    children: [{
        meta: {
          title: '运营档案',
          isMenu: true,
          keepAlive: true
        },
        name: 'operationsArchive',
        path: '/operate/archive',
        component: () => import('../views/operationsArchive/index.vue')
      }, {
        meta: {
          title: '新增',
          isMenu: true,
          keepAlive: true
        },
        name: 'addEditor',
        path: '/operate/add',
        component: () => import('../views/operationsArchive/addEditor.vue')
      },
      {
        meta: {
          title: '编辑',
          isMenu: true,
          keepAlive: true
        },
        name: 'addEditor',
        path: '/operate/editor',
        component: () => import('../views/operationsArchive/addEditor.vue')
      },
      {
        meta: {
          title: '内容管理',
          isMenu: true,
          keepAlive: true
        },
        name: 'operateContent',
        path: '/operate/content',
        component: () => import('../views/operationsArchive/operateContent.vue')
      },
      {
        meta: {
          title: '详情',
          isMenu: true,
          keepAlive: true
        },
        name: 'customerResponse',
        path: '/operate/response',
        component: () => import('../views/operationsArchive/customerResponse.vue')
      },
      // 运营分析
      {
        meta: {
          title: '运营分析',
          isMenu: true,
          keepAlive: true
        },
        name: 'analysis',
        path: '/operate/analysis',
        component: () => import('../views/operationsArchive/analysis.vue'),
      },
      {
        meta: {
          title: '运营详情',
          isMenu: true,
          keepAlive: true
        },
        name: 'contentDetails',
        path: '/operate/contentDetails',
        component: () => import('../views/operationsArchive/components/contentDetails.vue')
      },
      {
        meta: {
          title: '运营详情',
          isMenu: true,
          keepAlive: true
        },
        name: 'enterpriseDetails',
        path: '/operate/enterpriseDetails',
        component: () => import('../views/operationsArchive/components/enterpriseDetails.vue')
      }
    ]
  },
  {
    path: '/clue',
    component: RouterView,
    name: 'Clue',
    redirect: '/clue/index',
    ame: 'Clue',
    meta: {
      // title: '线索服务中心',
      title: '商机管理中心',
      isMenu: true
    },
    children: [
      {
        name: 'clue',
        path: '/clue/index',
        component: () => import('../views/clue/index'),
        meta: {
          // title: '潜在线索',
          title: '线索管理',
          isMenu: true
        },
      },
      {
        name: 'latentclue',
        path: '/latentclue/index',
        component: () => import('../views/clue/latentclue/index'),
        meta: {
          // title: '潜在线索',
          title: '潜在线索',
          isMenu: true
        },
      },
      {
        name: 'latentclue',
        path: '/latentclue/info',
        component: () => import('../views/clue/latentclue/info.vue'),
        meta: {
          // title: '潜在线索',
          title: '潜在线索',
          isMenu: true
        },
      },
      {
        name: 'recommendations',
        path: '/recommendations/index',
        component: () => import('../views/clue/recommendations/index'),
        meta: {
          // title: '潜在线索',
          title: '售前建议',
          isMenu: true  
        },
      },
      {
        name: 'addrecommend',
        path: '/recommendations/addrecommend',
        component: () => import('../views/clue/recommendations/addrecommend'),
        meta: {
          // title: '潜在线索',
          title: '售前建议',
          isMenu: true
        },
      },
      {
        name: 'opportunity',
        path: '/clue/opportunity',
        component: () => import('../views/clue/opportunity/index'),
        meta: {
          // title: '商机服务',
          title: '商机管理',
          isMenu: true
        },
      },
      {
        name: 'distribution',
        path: '/clue/distribution',
        component: () => import('../views/clue/distribution/index'),
        meta: {
          // title: '商机服务',
          title: '商机分配',
          isMenu: true
        },
      },
      {
        name: 'business',
        path: '/clue/business',
        component: () => import('../views/clue/business/index'),
        meta: {
          title: '业务全景',
          isMenu: true
        },
      },
      {
        name: 'combination',
        path: '/clue/combination',
        component: () => import('../views/clue/combination.vue'),
        meta: {
          title: '编辑线索',
          isMenu: true
        },
      },
      {
        name: 'configEnterprise',
        path: '/clue/configEnterprise',
        component: () => import('../views/clue/components/ConfigEnterprise'),
        meta: {
          title: '配置企业',
          isMenu: true
        },
      },
      {
        name: 'service',
        path: '/clue/service',
        component: () => import('../views/clue/components/service'),
        meta: {
          title: '推送企业',
          isMenu: true
        },
      },
      {
        name: 'pushEnterprise',
        path: '/clue/pushEnterprise',
        component: () => import('../views/clue/components/PushEnterprise'),
        meta: {
          title: '服务商',
          isMenu: true
        },
      },
      {
        name: 'expertPeople',
        path: '/clue/expertpeople',
        component: () => import('../views/clue/components/expertPeople'),
        meta: {
          title: '专家',
          isMenu: true
        },
      },
      {
        name: 'clueLog',
        path: '/clue/cluelog',
        component: () => import('../views/clue/components/clueLog'),
        meta: {
          title: '线索日志',
          isMenu: true
        },
      },
      {
        name: 'clueAssessment',
        path: '/clue/assessment',
        component: () => import('../views/clue/components/clueAssessment'),
        meta: {
          title: '线索评估',
          isMenu: true
        },
      },
      {
        name: 'checkUsers',
        path: '/clue/checkUsers',
        component: () => import('../views/clue/components/CheckUsers'),
        meta: {
          title: '查看用户',
          isMenu: true
        },
      },
      {
        name: 'shareUsers',
        path: '/clue/shareUsers',
        component: () => import('../views/clue/components/ShareUsers'),
        meta: {
          title: '分享用户',
          isMenu: true
        },
      },
      {
        name: 'indifferentUsers',
        path: '/clue/indifferentUsers',
        component: () => import('../views/clue/components/IndifferentUsers'),
        meta: {
          title: '参与用户',
          isMenu: true
        }
      },
      {
        name: 'compile',
        path: '/opportunity/compile',
        component: () => import('../views/clue/opportunity/components/compile.vue'),
        meta: {
          title: '商机详情',
          isMenu: true
        }
      },
      {
        name: 'expertOpportunity',
        path: '/opportunity/expertOpportunity',
        component: () => import('../views/clue/opportunity/components/expertOpportunity.vue'),
        meta: {
          title: '已推送专家数',
          isMenu: true
        }
      },
      {
        name: 'serviceOpportunity',
        path: '/opportunity/serviceOpportunity',
        component: () => import('../views/clue/opportunity/components/serviceOpportunity.vue'),
        meta: {
          title: '服务商',
          isMenu: true
        }
      },
      {
        name: 'journalOpportunity',
        path: '/opportunity/journalOpportunity',
        component: () => import('../views/clue/opportunity/components/journalOpportunity.vue'),
        meta: {
          title: '商机日志',
          isMenu: true
        }
      },
      {
        name: 'assessmentOpportunity',
        path: '/opportunity/assessmentOpportunity',
        component: () => import('../views/clue/opportunity/components/assessmentOpportunity.vue'),
        meta: {
          title: '商机评估',
          isMenu: true
        }
      },
      {
        name: 'matchingManagement',
        path: '/opportunity/matchingManagement',
        component: () => import('../views/clue/opportunity/components/matchingManagement.vue'),
        meta: {
          title: '匹配管理',
          isMenu: true
        }
      },
    ]
  },
  {
    path: '/expert',
    component: RouterView,
    redirect: '/expert/index',
    name: '   Expert',
    meta: {
      title: '专家服务中心',
      isMenu: true,
      keepAlive: true
    },
    children: [{
        name: 'expert',
        path: '/expert/index',
        component: () => import('../views/expert/index'),
        meta: {
          title: '专家服务中心',
          isMenu: true,
          keepAlive: true
        }

      },
      {
        path: '/expert/addexpert',
        name: 'addexpert',
        component: () => import('../views/expert/addexpert.vue'),
        meta: {
          title: '专家管理',
          isMenu: true
        }
      },
      {
        path: '/expert/checkCount',
        name: 'checkCount',
        component: () => import('../views/expert/checkCount.vue'),
        meta: {
          title: '查看人数',
          isMenu: true
        }
      },
      {
        path: '/expert/expertSharing',
        name: 'expertSharing',
        component: () => import('../views/expert/checkCount.vue'),
        meta: {
          title: '分享人数',
          isMenu: true
        }
      },
      {
        path: '/expert/PushexpertCompany',
        name: 'PushexpertCompany',
        component: () => import('../views/expert/PushexpertCompany.vue'),
        meta: {
          title: '已推送企业数',
          isMenu: true
        }
      },

      {
        path: '/expert/configureEnterprise',
        name: 'expertConfigureEnterprise',
        component: () => import('../views/expert/configureEnterprise.vue'),
        meta: {
          title: '匹配',
          isMenu: true
        }
      },
      {
        path: '/expert/SeekAdvice',
        name: 'SeekAdvice',
        component: () => import('../views/expert/components/SeekAdvice.vue'),
        meta: {
          title: '咨询用户数',
          isMenu: true
        }
      }

    ]

  },
  {
    path: '/order/index',
    name: 'Order',
    component: RouterView,
    redirect: '/order/index',

    meta: {
      title: '订单管理中心',
      isMenu: true
    },
    children: [{
        name: 'order',
        path: '/order/index',
        component: () => import('../views/order/index'),

      },

    ]

  },
  {
    path: '/system/index',
    name: 'system',
    component: RouterView,
    redirect: '/system/index',

    meta: {
      title: '系统管理',
      isMenu: true
    },
    children: [{
        name: 'idea',
        path: '/system/idea',
        component: () => import('../views/system/idea/index'),
        meta: {
          title: '意见管理',
          isMenu: true
        }
      },
      {
        name: 'setup',
        path: '/system/ginseng',
        component: () => import('../views/system/ginseng/index'),
        meta: {
          title: '智参设置',
          isMenu: true
        }
      },
      {
        name: 'Smartselection',
        path: '/system/Smartselection',
        component: () => import('../views/system/Smartselection/index'),
        meta: {
          title: '智选设置',
          isMenu: true
        }
      },

      {
        name: 'labelmanage',
        path: '/system/labelmanage',
        component: () => import('../views/system/labelmanage/index'),
        meta: {
          title: '标签管理',
          isMenu: true
        }
      },
      {
        name: 'addlabel',
        path: '/system/addlabel',
        component: () => import('../views/system/labelmanage/components/addlabel'),
        meta: {
          title: '标签管理',
          isMenu: true
        },
      },
      {
        name: 'feedback',
        path: '/system/feedback',
        component: () => import('../views/system/feedback'),
        meta: {
          title: '反馈收集',
          isMenu: true
        }
      },
      {
        name: 'menuindex',
        path: '/system/menu',
        component: () => import('../views/system/menu'),
        meta: {
          title: '菜单管理',
          isMenu: true
        }
      },
      {
        name: 'matemanage',
        path: '/system/matemanage',
        component: () => import('../views/system/matemanage'),
        meta: {
          title: '匹配管理',
          isMenu: true
        }
      },
      {
        name: 'addArithmetic',
        path: '/system/addArithmetic',
        component: () => import('../views/system/matemanage/components/addArithmetic'),
        meta: {
          title: '算法管理',
          isMenu: true
        }
      },
      {
        name: 'weeklymanage',
        path: '/system/weeklymanage',
        component: () => import('../views/system/weeklymanage'),
        meta: {
          title: '周报管理',
          isMenu: true
        }
      }
    ]

  },
  {
    path: '/content/report',
    component: RouterView,
    redirect: '/content/report',
    meta: {
      title: '报告管理',
      isMenu: true,
      keepAlive: true
    },
    children: [{
        path: '/content/report',
        component: () => import('../views/content/pageList/report'),
        name: 'Report',
        meta: {
          title: '报告管理',
          isMenu: true,
          keepAlive: true
        }
      },

      {
        path: '/content/configureReport',
        name: 'contentConfigureReport',
        component: () => import('../views/content/pageList/configureReport.vue'),
        meta: {
          title: '匹配',
          isMenu: true
        }
      },
      {
        path: '/content/editReport',
        name: 'contentEditReport',
        component: () => import('../views/content/pageList/editReport.vue'),
        meta: {
          title: '编辑',
          isMenu: true
        }
      },
      {
        name: 'listreportCheckPage',
        path: '/report/listusertCheckPage',
        component: () => import('../views/content/pageList/reportView'),
        meta: {
          title: '查看人数',
          isMenu: true
        }
      },
      {
        name: 'ShareUser',
        path: '/report/ShareUser',
        component: () => import('../views/content/pageList/reportView'),
        meta: {
          title: '分享人数',
          isMenu: true
        }
      },
      {
        name: 'downloadCount',
        path: '/report/downloadCount',
        component: () => import('../views/content/pageList/downloadCount.vue'),
        meta: {
          title: '下载人数',
          isMenu: true
        }
      },
      {
        name: 'PushreportCompany',
        path: '/report/PushreportCompany',
        component: () => import('../views/content/pageList/PushreportCompany.vue'),
        meta: {
          title: '已推送企业数',
          isMenu: true
        }
      }
    ]
  },
  // 内容管理中心
  {
    path: '/content',
    name: 'ContentList',
    component: RouterView,
    redirect: '/content/list',
    meta: {
      title: '内容管理中心',
      isMenu: true
    },
    children: [{
        path: '/content/list',
        component: () => import('../views/content'),
        name: 'Content',
        meta: {
          title: '内容管理',
          isMenu: true
        }
      },
      {
        path: '/content/redact',
        name: 'contentRedact',
        component: () => import('../views/content/pageList/redactPage.vue'),
        meta: {
          title: '编辑',
          isMenu: true
        }
      },
      {
        path: '/content/add',
        name: 'contentAdd',
        component: () => import('../views/content/pageList/redactPage.vue'),
        meta: {
          title: '新增',
          isMenu: true
        }
      },
      {
        path: '/content/matching',
        name: 'contentMatching',
        component: () => import('../views/content/pageList/matchingPage.vue'),
        meta: {
          title: '匹配',
          isMenu: true
        }
      },
      {
        path: '/content/particulars',
        name: 'particulars',
        component: () => import('../views/content/pageList/particularsPage.vue'),
        meta: {
          title: '对接人数',
          isMenu: true
        }
      },
      {
        path: '/content/check',
        name: 'contentcheck',
        component: () => import('../views/content/pageList/shareCount.vue'),
        meta: {
          title: '查看人数',
          isMenu: true
        }
      },

      {
        path: '/content/share',
        name: 'contentShare',
        component: () => import('../views/content/pageList/shareCount.vue'),
        meta: {
          title: '分享人数',
          isMenu: true
        }
      },
      {
        path: '/content/PushEnterprise',
        name: 'PushEnterprise',
        component: () => import('../views/content/pageList/PushEnterprise.vue'),
        meta: {
          title: '已匹配企业数',
          isMenu: true
        }
      },
      {
        path: '/content/relation',
        name: 'contentRelation',
        component: () => import('../views/content/pageList/particularsPage.vue'),
        meta: {
          title: '想联系人数',
          isMenu: true
        }
      },
      {
        path: '/activity',
        name: 'Activity',
        component: RouterView,
        redirect: '/activity/list',
        meta: {
          title: '活动服务中心',
          isMenu: true,
          keepAlive: true
        },
        children: [{
            meta: {
              // title: '在线服务',
              isMenu: true,
              keepAlive: true
            },
            name: 'activityList',
            path: '/activity/list',
            component: () => import('../views/activity/index.vue')
          },
          {
            path: '/activity/editActivity',
            name: 'activityEditActivity',
            component: () => import('../views/activity/pageList/editActivity.vue'),
            meta: {
              title: '编辑',
              isMenu: true
            }
          },
          {
            path: '/activity/configureEnterprise',
            name: 'activityConfigureEnterprise',
            component: () => import('../views/activity/pageList/configureEnterprise.vue'),
            meta: {
              title: '匹配',
              isMenu: true
            }
          },
          {
            path: '/activity/accountManagement',
            name: 'accountManagement',
            component: () => import('../views/activity/components/accountManagement.vue'),
            meta: {
              title: '报名管理',
              isMenu: true
            }
          },
          {
            path: '/activity/checkCount',
            name: 'activitycheckCount',
            component: () => import('../views/activity/pageList/checkCount.vue'),
            meta: {
              title: '查看人数',
              isMenu: true
            }
          },
          {
            path: '/activity/activitySharing',
            name: 'activitySharing',
            component: () => import('../views/activity/pageList/checkCount.vue'),
            meta: {
              title: '分享人数',
              isMenu: true
            }
          },
          {
            path: '/activity/PushactivityCompany',
            name: 'PushactivityCompany',
            component: () => import('../views/activity/pageList/PushactivityCompany.vue'),
            meta: {
              title: '已推送企业数',
              isMenu: true
            }
          }
        ]
      },
    ]
  },

  {
    path: '/consulting',
    name: 'Consulting',
    component: RouterView,
    redirect: '/consulting/list',
    meta: {
      title: '咨询管理',
      isMenu: true
    },
    children: [{
        meta: {
          title: '咨询管理',
          isMenu: true
        },
        name: 'ConsultingList',
        path: '/consulting/list',
        component: () => import('../views/consulting/list/index.vue')
      },
      {
        meta: {
          title: '话术管理',
          isMenu: true
        },
        name: 'Script',
        path: '/consulting/script'
      }
    ]
  },
  {
    path: '/diagnosis',
    name: 'Diagnosis',
    component: RouterView,
    redirect: '/diagnosis/record',
    meta: {
      title: '诊断服务中心',
      isMenu: true,
      keepAlive: true
    },
    children: [{
        meta: {
          title: '诊断记录',
          isMenu: true,
          keepAlive: true
        },
        name: 'Diagnosisrecord',
        path: '/diagnosis/record',
        component: () => import('../views/diagnosis/record/index.vue')
      },
      {
        meta: {
          title: '预约记录',
          isMenu: true
        },
        name: 'make',
        path: '/diagnosis/make',
        component: () => import('../views/diagnosis/components/make.vue')
      },
      {
        path: '/diagnosis/recordinfo',
        name: 'recordinfo',
        component: () => import('../views/diagnosis/record/recordinfo.vue'),
        meta: {
          title: '诊断详情',
          isMenu: true
        }
      },
      {
        meta: {
          title: '诊断工具',
          isMenu: true
        },
        name: 'diagnosticTool',
        path: '/diagnosis/diagnostictool',
        component: () => import('../views/diagnosis/diagnosticTools/index.vue')
      },
      {
        meta: {
          title: '工具详情',
          isMenu: true
        },
        name: 'addDiagnosticTool',
        path: '/diagnosis/add',
        component: () =>
          import('../views/diagnosis/diagnosticTools/components/addDiagnosticTool.vue')
      },
      {
        meta: {
          title: '配置行业',
          isMenu: true
        },
        name: 'configIndustry',
        path: '/diagnosis/configindustry',
        component: () => import('../views/diagnosis/diagnosticTools/components/configIndustry.vue')
      },
      {
        meta: {
          title: '诊断工具配置',
          isMenu: true
        },
        name: 'manageTrouble',
        path: '/diagnosis/managetrouble',
        // redirect: '/diagnosis/managetrouble/configtroublegroup',
        component: () => import('../views/diagnosis/diagnosticTools/components/manageTrouble.vue')
        // children: [{
        //     meta: {
        //       //配置问题组
        //       title: '诊断工具配置',
        //       isMenu: true
        //     },
        //     name: 'configTroubleGroup',
        //     path: '/diagnosis/managetrouble/configtroublegroup',
        //     component: () => import('../views/diagnosis/diagnosticTools/components/configTroubleGroup.vue')
        //   },
        //   {
        //     //选择问题
        //     meta: {
        //       title: '诊断工具配置',
        //       isMenu: true
        //     },
        //     name: 'configTroubleGroup',
        //     path: '/diagnosis/managetrouble/selecttrouble',
        //     component: () => import('../views/diagnosis/diagnosticTools/components/selectTrouble.vue')
        //   },
        //   {
        //     //配置问题属性
        //     meta: {
        //       title: '诊断工具配置',
        //       isMenu: true
        //     },
        //     name: 'setTroubleProperty',
        //     path: '/diagnosis/managetrouble/settroubleproperty',
        //     component: () => import('../views/diagnosis/diagnosticTools/components/setTroubleProperty.vue')
        //   },
        // ]
      },
      {
        meta: {
          title: '问题管理',
          isMenu: true
        },
        name: 'troubleManagement',
        path: '/diagnosis/troublemanagement',
        component: () => import('../views/diagnosis/troubleManagement/index.vue')
      },
      {
        meta: {
          title: '添加问题',
          isMenu: true
        },
        name: 'addTrouble',
        path: '/diagnosis/addtrouble',
        component: () => import('../views/diagnosis/troubleManagement/components/addTrouble.vue')
      },
      {
        meta: {
          title: '政策管理',
          isMenu: true
        },
        name: 'policyManagement',
        path: '/diagnosis/policymanagement',
        component: () => import('../views/diagnosis/policyManagement/index.vue')
      },
      {
        meta: {
          title: '添加政策',
          isMenu: true
        },
        name: 'addPolicy',
        path: '/diagnosis/addpolicy',
        component: () => import('../views/diagnosis/policyManagement/components/addPolicy.vue')
      },
    ]
  },
  {
    path: '/user',
    name: 'Userlist',
    component: RouterView,
    redirect: '/user/list',
    meta: {
      title: '平台用户中心',
      isMenu: true
    },
    children: [{
        meta: {
          title: '用户列表',
          isMenu: true
        },
        name: 'User',
        path: '/user/list',
        component: () => import('../views/user/list/index.vue')
      },
      {
        meta: {
          title: '审核管理',
          isMenu: true
        },
        name: 'Examine',
        path: '/user/examine',
        component: () => import('../views/user/examine/index.vue')
      }
    ]
  },
  {
    path: '/customer',
    name: 'Customer',
    component: RouterView,
    redirect: '/customer/list',
    meta: {
      title: '智参客户企业',
      isMenu: true,
      keepAlive: true
    },
    children: [{
        meta: {
          // title: '在线服务',
          isMenu: true,
          keepAlive: true
        },
        name: 'customerList',
        path: '/customer/list',
        component: () => import('../views/customer/index.vue')
      },
      {
        name: 'AccountManagement',
        path: '/customer/CorrelateUser',
        component: () => import('../views/customer/components/accountManagement'),
        meta: {
          title: '账号管理',
          isMenu: true
        }
      },
      {
        name: 'weekly',
        path: '/customer/weekly',
        component: () => import('../views/customer/components/weekly'),
        meta: {
          title: '智参周报',
          isMenu: true
        }
      },
      {
        name: 'EnterpriseContent',
        path: '/customer/EnterpriseContent',
        component: () => import('../views/customer/components/EnterpriseContent'),
        meta: {
          title: '内容管理',
          isMenu: true
        }
      },

      {
        name: 'addenterprise',
        path: '/customer/addenterprise',
        component: () => import('../views/customer/components/addenterprise'),
        meta: {
          title: '编辑基本资料',
          isMenu: true
        }
      },
      {
        name: 'Reporting',
        path: '/customer/Reporting',
        component: () => import('../views/customer/Reporting'),
        meta: {
          title: '报备中心',
          isMenu: true
        }
      }
    ]
  },
  {
    path: '/adviser',
    name: 'Adviser',
    component: RouterView,
    redirect: '/adviser/online',
    meta: {
      title: '顾问服务中心',
      isMenu: true
    },
    children: [{
        meta: {
          title: '在线服务',
          isMenu: true
        },
        name: 'adviser',
        path: '/adviser/online',
        component: () => import('../views/adviser/online/index.vue')
      },
      {
        path: '/adviser/onlinedetail',
        name: 'onlinedetail',
        component: () => import('../views/adviser/online/component/onlinedetail.vue'),
        meta: {
          title: '咨询管理',
          isMenu: true
        }
      }
    ]
  },
  {
    path: '/activity',
    name: 'Activity',
    component: RouterView,
    redirect: '/activity/list',
    meta: {
      title: '活动服务中心',
      isMenu: true,
      keepAlive: true
    },
    children: [{
        meta: {
          // title: '在线服务',
          isMenu: true,
          keepAlive: true
        },
        name: 'activityList',
        path: '/activity/list',
        component: () => import('../views/activity/index.vue')
      },
      {
        path: '/activity/editActivity',
        name: 'activityEditActivity',
        component: () => import('../views/activity/pageList/editActivity.vue'),
        meta: {
          title: '编辑',
          isMenu: true
        }
      },
      {
        path: '/activity/configureEnterprise',
        name: 'activityConfigureEnterprise',
        component: () => import('../views/activity/pageList/configureEnterprise.vue'),
        meta: {
          title: '匹配',
          isMenu: true
        }
      },
      {
        path: '/activity/accountManagement',
        name: 'accountManagement',
        component: () => import('../views/activity/components/accountManagement.vue'),
        meta: {
          title: '报名管理',
          isMenu: true
        }
      },
      {
        path: '/activity/checkCount',
        name: 'activitycheckCount',
        component: () => import('../views/activity/pageList/checkCount.vue'),
        meta: {
          title: '查看人数',
          isMenu: true
        }
      },
      {
        path: '/activity/activitySharing',
        name: 'activitySharing',
        component: () => import('../views/activity/pageList/checkCount.vue'),
        meta: {
          title: '分享人数',
          isMenu: true
        }
      },
      {
        path: '/activity/PushactivityCompany',
        name: 'PushactivityCompany',
        component: () => import('../views/activity/pageList/PushactivityCompany.vue'),
        meta: {
          title: '已推送企业数',
          isMenu: true
        }
      }
    ]
  },
]

export default menuList
